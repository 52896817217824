<template>
  <div class="addview">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="客户姓名">
        <el-input v-model="form.customerName"></el-input>
      </el-form-item>
      <el-form-item label="客户手机号">
        <el-input v-model="form.phoneNumber"></el-input>
      </el-form-item>
      <el-form-item label="客诉类型">
        <el-radio-group v-model="form.customerComplaintType">
          <el-radio :label="0">APP故障</el-radio>
          <el-radio :label="1">系统故障</el-radio>
          <el-radio :label="2">硬件维修</el-radio>
          <el-radio :label="3">技术支持</el-radio>
          <el-radio :label="4">使用咨询</el-radio>
          <el-radio :label="5">商务咨询</el-radio>
          <el-radio :label="6">投诉建议</el-radio>
          <el-radio :label="7">经销商问题</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="客诉标题">
        <el-autocomplete
          class="inline-input"
          v-model="form.title"
          :fetch-suggestions="querySearch"
          placeholder="请选择或输入内容"
          @select="handleSelect"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="客诉描述">
        <el-input type="textarea" v-model="form.description"></el-input>
      </el-form-item>
      <el-form-item label="即时解决">
        <el-switch v-model="dcjj"></el-switch>
      </el-form-item>
      <el-form-item v-if="dcjj" label="注：">
        <div v-if="dcjj">及时解决为电话沟通阶段便解决</div>
      </el-form-item>

      <el-form-item v-else label="紧急程度" class="flex">
        <el-radio class="redparent" v-model="form.urgentType" :label="0">
          <span class="red">特殊加急</span>
        </el-radio>
        <el-radio class="originparent" v-model="form.urgentType" :label="1"
          ><span class="origin">较为紧急</span></el-radio
        >
        <el-radio class="blueparent" v-model="form.urgentType" :label="2"
          ><span class="blue">一般紧急</span></el-radio
        >
        <el-radio class="grayparent" v-model="form.urgentType" :label="3"
          ><span class="gray">普通客诉</span></el-radio
        >
      </el-form-item>

      <el-form-item>
        <el-button :disabled="cando" type="primary" @click="addorder"
          >立即创建</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addOrderUrl } from "@/api/order";

export default {
  data() {
    return {
      cando: false,
      list: ["普通客诉", "一般紧急", "较为紧急", "特殊加急"],
      restaurants: [],
      state1: "",
      state2: "",
      dcjj: false,
      form: {
        customerName: "",
        phoneNumber: "",
        customerComplaintType: 0,
        title: "",
        description: "",
        urgentType: 3,
        type: null,
      },
    };
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    async addorder() {
      this.cando = true;
      if (this.dcjj === true) {
        this.form.type = 0;
        this.form.urgentType = 3;
      } else {
        this.form.type = 1;
      }
      try {
        const res = await addOrderUrl(this.form);
        if (res.data.code === 200) {
          this.$message({
            message: "创建成功",
            type: "success",
          });
          this.dcjj = false;
          (this.form = {
            customerName: "",
            phoneNumber: "",
            customerComplaintType: 0,
            title: "",
            description: "",
            urgentType: 3,
            type: null,
          }),
            this.$emit("ChangeAddVisible", false);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.cando = false;
      }
    },
    loadAll() {
      return [
        { value: "卡停机" },
        { value: "卡充值问题" },
        { value: "平台到期 " },
        { value: "设备离线" },
        { value: "换卡" },
        { value: "返修咨询" },
        { value: "设备解绑问题" },
        { value: "声控失灵" },
        { value: "指令问题" },
        { value: "到期延长" },
        { value: "到期延长" },
        { value: "api接口问题" },
        { value: "APP无法登陆" },
        { value: "APP如何下载" },
        { value: "AMS创建客户" },
      ];
    },
    handleSelect(item) {
      console.log(item);
    },
  },
  mounted() {
    this.restaurants = this.loadAll();
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  ::v-deep.el-form-item__content {
    margin-left: 10px !important;
  }
}

.red {
  border-radius: 5px;
  padding: 3px 8px;
  color: #ffffff;
  background-color: #f56c6c;
}
.origin {
  border-radius: 5px;
  padding: 3px 8px;
  color: #ffffff;
  background-color: #e7a23c;
}
.blue {
  border-radius: 5px;
  padding: 3px 8px;
  color: #ffffff;
  background-color: #409eff;
}
.gray {
  border-radius: 5px;
  padding: 3px 8px;
  color: #ffffff;
  background-color: #909399;
}
::v-deep.redparent {
  .is-checked {
    .el-radio__inner {
      border-color: #f56c6c !important;
      background: #f56c6c !important;
    }
  }
}
::v-deep.originparent {
  .is-checked {
    .el-radio__inner {
      border-color: #e7a23c !important;
      background: #e7a23c !important;
    }
  }
}
::v-deep.blueparent {
  .is-checked {
    .el-radio__inner {
      border-color: #409eff !important;
      background: #409eff !important;
    }
  }
}
::v-deep.grayparent {
  .is-checked {
    .el-radio__inner {
      border-color: #909399 !important;
      background: #909399 !important;
    }
  }
}
</style>
