<template>
  <div class="article-container">
    <el-card class="filter-card">
      <el-form
        ref="form"
        label-width="80px"
        size="mini"
      >
        <div class="flexparents">
          开始日期:
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="form.createTimeAfter"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
          结束日期:
          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.creatTimeBefore"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>

          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="cleardata()"
          >清空</el-button>
          <el-button
            type="primary"
            :disabled="loading"
            @click="loadArticles(1)"
          >查询</el-button>
          <el-button
            type="warning"
            :disabled="loading"
            @click="addorder()"
          >新增工单</el-button>
          <el-button
            type="info"
            :disabled="loading"
            @click="ifgaoji = !ifgaoji"
          >{{ ifgaoji ? "收起" : "高级查询" }}</el-button>
        </div>
        <div
          v-if="ifgaoji"
          class="flexparents zdhh"
        >
          <div>
            工单号:
            <el-input
              class="input rightgap"
              v-model="form.workOrderNo"
              placeholder="请输入工单号"
            ></el-input>
          </div>
          <div>
            创建人:
            <el-input
              class="input rightgap"
              v-model="form.createdName"
              placeholder="请输入创建人"
            ></el-input>
          </div>
          <div>
            是否转出:
            <el-select
              class="flexparents rightgap"
              v-model="form.transformFlag"
              placeholder="请选择"
            >
              <el-option
                v-for="item in transformFlagoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            客诉类型:
            <el-select
              v-model="form.customerComplaintType"
              placeholder="请选择"
              class="rightgap"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div>
            进度查询:
            <el-select
              class="flexparents rightgap"
              v-model="form.processes"
              placeholder="请选择"
            >
              <el-option
                v-for="item in stateoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            客户姓名:
            <el-input
              class="input rightgap"
              v-model="form.customerName"
              placeholder="请输入客户"
            ></el-input>
          </div>
          <div>
            手机号:
            <el-input
              class="input"
              v-model="form.phoneNumber"
              placeholder="请输入客诉手机号"
            ></el-input>
          </div>
          <div>
            诉源:
            <el-select
              class="flexparents rightgap"
              v-model="form.selfFlag"
              placeholder="请选择"
            >
              <el-option
                v-for="item in fromoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            当前站点:
            <el-select
              class="flexparents rightgap"
              v-model="form.stations"
              placeholder="请选择"
            >
              <el-option
                v-for="item in stationsoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            紧急程度:
            <el-select
              class="flexparents rightgap"
              v-model="form.urgentType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in urgentTypeoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <el-switch
            v-model="form.currentUserFlag"
            active-text="全部员工"
            inactive-text="当前员工"
            active-value="false"
            inactive-value="true"
          >
          </el-switch>
        </div>
      </el-form>

    </el-card>

    <el-card class="box-card">
      <div
        slot="header"
        class="clearfix"
      >
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>
      <!-- 数据列表 -->
      <!--
        Table 表格组件
        1、把需要展示的数组列表数据绑定给 table 组件的 data 属性
          注意：你不用去 v-for 遍历，它自己会遍历
        2、设计表格列 el-table-column
          width 可以设定表格列的宽度
          label 可以设定列的标题
          prop  用来设定要渲染的列表项数据字段，只能展示文本

        3、表格列默认只能渲染普通文本，如果需要展示其它内容，例如放个按钮啊、放个图片啊，那就需要自定义表格列模板了：https://element.eleme.cn/#/zh-CN/component/table#zi-ding-yi-lie-mo-ban
       -->
      <el-table
        :data="articles"
        stripe
        :height="tableHeight"
        class="list-table"
        size="mini"
        v-loading="loading"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="orderNo"
          label="工单号"
        > </el-table-column>
        <el-table-column
          prop="createdName"
          label="创建人"
        ></el-table-column>

        <el-table-column label="溯源">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="Station[scope.row.createdByStation].type">{{
              Station[scope.row.createdByStation].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          prop="customerName"
          label="客户姓名"
        >
        </el-table-column>
        <el-table-column
          label="紧急程度"
          width="120"
        >
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="urgentStatus[scope.row.urgentType].type">{{
              urgentStatus[scope.row.urgentType].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          label="当前工站"
          key="station"
        >
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="Station[scope.row.station].type">{{
              Station[scope.row.station].text
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="completedByName"
          label="完成人"
        >
        </el-table-column>
        <el-table-column label="是否转出">
          <template slot-scope="scope">
            <div v-if="scope.row.transformFlag">已转出</div>
            <div v-else>未转出</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="客诉标题"
        > </el-table-column>
        <el-table-column
          prop="faeOperatorName"
          label="FAE责任人"
        >
        </el-table-column>

        <el-table-column
          width="250"
          prop="description"
          label="问题描述"
        >
        </el-table-column>

        <el-table-column
          prop="phoneNumber"
          label="客户手机号"
        >
        </el-table-column>
        <el-table-column
          v-if="showremake"
          width="250"
          prop="remark"
          label="客诉详情"
        >
        </el-table-column>
        <el-table-column
          prop="createdTime"
          label="创建时间"
        > </el-table-column>
        <el-table-column label="客诉类型">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag
              effect="plain"
              :type="articleStatus[scope.row.customerComplaintType].type"
            >{{ articleStatus[scope.row.customerComplaintType].text }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="进度"
          key="progress"
        >
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="progress[scope.row.process].type">{{
              progress[scope.row.process].text
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="操作"
        >
          <template slot-scope="scope">
            <div>
              <!-- <el-tooltip
                content="修改订单"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="changedata(scope.row)"
                  circle
                  icon="el-icon-edit"
                  type="primary"
                ></el-button>
              </el-tooltip> -->
              <el-tooltip
                v-if="scope.row.process === 5"
                content="重新打开FAE工单"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="reopen(scope.row)"
                  circle
                  icon="el-icon-refresh"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                v-if="scope.row.process === 5"
                content="完成"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="finish(scope.row)"
                  circle
                  icon="el-icon-close"
                  type="primary"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                content="处理客诉"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="addplan(scope.row)"
                  circle
                  icon="el-icon-s-tools"
                  type="primary"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                style="color: white; background: red; border-color: red"
                content="删除"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="delect(scope.row)"
                  circle
                  icon="el-icon-delete"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- /数据列表 -->

      <!-- 列表分页 -->
      <!--
        total 用来设定总数据的条数
        它默认按照 10 条每页计算总页码
        page-size 每页显示条目个数，支持 .sync 修饰符，默认每页 10 条

        90 3 90 / 3 = 30
       -->
      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="处理客诉"
      width="1150px"
      :visible.sync="planVisable"
      append-to-body
    >

      <el-steps
        v-if="customerWorkOrderVo.transformFlag == true "
        :active="steptype"
        finish-status="success"
        class="gap"
        style="padding-left: 16px; padding-right: 16px"
      >
        <el-step
          title="开始"
          :description="customerWorkOrderVo.createdTime"
        ></el-step>
        <el-step
          title="处理中"
          :description="customerWorkOrderVo.createdTime"
        ></el-step>
        <el-step
          title="转至FAE"
          :description="technicalSupportWorkOrderVo.createdTime"
        ></el-step>
        <el-step
          title="FAE处理中"
          :description="technicalSupportWorkOrderVo.firstBrowseTime"
        ></el-step>
        <el-step
          title="FAE处理完成"
          :description="technicalSupportWorkOrderVo.completedTime"
        ></el-step>
        <el-step
          title="待确认完成"
          :description="technicalSupportWorkOrderVo.completedTime"
        ></el-step>
        <el-step
          title="完成"
          :description="customerWorkOrderVo.completedTime"
        ></el-step>
      </el-steps>

      <div class="kesuitem">
        客诉标题：
        <el-autocomplete
          class="inline-input"
          v-model="solvform.title"
          :fetch-suggestions="querySearch"
          placeholder="请选择或输入内容"
          @select="handleSelect"
        ></el-autocomplete>
      </div>
      <div class="kesuitem">
        触发原因：
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入触发原因"
          v-model="solvform.reason"
        >
        </el-input>
      </div>
      <div class="kesuitem">
        解决方案：
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入解决方案"
          v-model="solvform.solution"
        >
        </el-input>
      </div>
      <div class="kesuitem">
        添加备注：
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入备注内容"
          v-model="solvform.remark"
        >
        </el-input>
      </div>
      <div class="kesuitem">
        解决模式：
        <el-radio-group v-model="solvform.mode">
          <el-radio :label="0">已解决客诉，并从根源解决</el-radio>
          <el-radio :label="1">已解决客诉，未从解决根源问题</el-radio>
          <el-radio :label="2">已解决客诉，未能找到根源问题</el-radio>
        </el-radio-group>
      </div>
      <div class="kesuitem">
        上传附件（仅限jpg, .png）
        <el-upload
          class="upload-demo"
          action="/api/procedure/customer-work-order/uploadImg"
          :on-success="uploadsuccess"
          multiple
          :limit="3"
          :file-list="fileList"
          :headers="multfilehead"
          :data="multfiledata"
        >
          <el-button
            size="mini"
            type="primary"
          >上传</el-button>
        </el-upload>
      </div>
      <div class="floter">
        <el-button
          type="success"
          plain
          @click="solveOrder"
        >确定</el-button>
        <el-button
          type="success"
          plain
          @click="opensetfaename = true"
        >转至FAE</el-button>
      </div>
    </el-dialog>

    <!-- <el-dialog
      title="是否确认完结此工单"
      width="250px"
      :visible.sync="ComplectVisible"
      append-to-body
    >
      <div>完成此工单，并发送短信给予客户告知完成</div>
      <el-button type="danger" @click="complateOrder" plain>确认完成</el-button>
      <el-button type="success" @click="cansel">暂不完成</el-button>
    </el-dialog> -->

    <el-dialog
      title="是否确认删除此工单"
      width="250px"
      :visible.sync="deletedvis"
      append-to-body
    >
      <div>{{ this.delectform.orderNof }}</div>
      <el-button
        type="danger"
        @click="deletedOrder"
        plain
      >确认删除</el-button>
      <el-button
        type="success"
        @click="cansel"
      >暂不删除</el-button>
    </el-dialog>

    <el-dialog
      title="添加工单"
      width="550px"
      :visible.sync="AddVisible"
      append-to-body
      @closed="reflesh()"
    >
      <AddView
        @ChangeAddVisible="makeAddfale"
        :data="dataildata"
      />
    </el-dialog>

    <el-dialog
      title="修改密码"
      :visible.sync="showchangepsw"
    >
      <el-form :model="form">
        <el-form-item label="原密码">
          <el-input
            v-model="changepsdform.oldPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input
            v-model="changepsdform.newPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showchangepsw = false">取 消</el-button>
        <el-button
          type="primary"
          @click="onChangePSWD"
        >确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="转移FAE"
      :visible.sync="opensetfaename"
    >
      <el-form :model="form">
        <el-form-item label="FAE跟进人姓名">
          <el-select
            v-model="solvform.faeOperatorName"
            placeholder="请选择"
          >
            <el-option
              v-for="item in FAElist"
              :key="item.userId"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="opensetfaename = false">取 消</el-button>
        <el-button
          type="primary"
          @click="sendFAE"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AddView from "./components/addwindows";
import { changePassWord } from "@/api/admin";
import { getFaeOperatorListUrl } from "@/api/fae";
import {
  reopenFaeUrl,
  SearchOrderUrl,
  solveOrderUrl,
  completeOrderUrl,
  OrderDelect,
  OrderTransform,
  OneOrderUrl,
} from "@/api/order";

export default {
  name: "ArticleIndex",
  components: { AddView },
  props: {},
  data() {
    return {
      opensetfaename: false,
      ifgaoji: false,
      showremake: false,
      changepsdform: {
        oldPassword: "",
        newPassword: "",
      },
      FAElist: [],
      tableHeight: window.innerHeight - 320,
      complaterform: { workOrderId: null },
      delectform: { workOrderId: null },
      fileList: [],
      textarea: "",
      ComplectVisible: false,
      AddVisible: false,
      planVisable: false,
      deletedvis: false,
      dataildata: "",
      multfilehead: {},
      multfiledata: {},
      options: [
        {
          value: null,
          label: "不限",
        },
        {
          value: "0",
          label: "App故障",
        },
        {
          value: "1",
          label: "系统故障",
        },
        {
          value: "2",
          label: "硬件维修",
        },
        {
          value: "3",
          label: "技术支持",
        },
        {
          value: "4",
          label: "使用咨询",
        },
        {
          value: "5",
          label: "商务咨询",
        },
        {
          value: "6",
          label: "投诉建议",
        },
        {
          value: "7",
          label: "经销商问题",
        },
      ],
      stationsoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "客服",
        },
        {
          value: "2",
          label: "FAE",
        },
        {
          value: "3",
          label: "研发",
        },
      ],
      urgentTypeoption: [
        {
          value: "0",
          label: "特殊加急",
        },
        {
          value: "1",
          label: "较为紧急",
        },
        {
          value: "2",
          label: "一般紧急",
        },
        {
          value: "3",
          label: "普通客诉",
        },
      ],
      fromoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "0",
          label: "自建",
        },
        {
          value: "1",
          label: "客诉",
        },
      ],
      transformFlagoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      stateoption: [
        {
          value: null,
          label: "不限",
        },
        {
          value: "0",
          label: "未完成",
        },
        {
          value: "1",
          label: "已完成",
        },
        {
          value: "2",
          label: "已完成，已评价",
        },
        {
          value: "3",
          label: "已转出",
        },
        {
          value: "5",
          label: "待完成",
        },
      ],
      value: "",
      articles: [], // 文章数据列表
      articleStatus: [
        { status: 0, text: "APP故障", type: "info" }, // 1
        { status: 1, text: "系统故障", type: "info" }, // 1
        { status: 2, text: "硬件维修", type: "info" }, // 1
        { status: 3, text: "技术支持", type: "info" }, // 1
        { status: 4, text: "使用咨询", type: "info" }, // 4
        { status: 5, text: "商务咨询", type: "info" }, // 1
        { status: 6, text: "投诉建议", type: "info" }, // 4
        { status: 7, text: "经销商问题", type: "info" }, // 4
      ],
      progress: [
        { status: 0, text: "未完成", type: "success" }, // 1
        { status: 1, text: "已完成", type: "danger" }, // 1
        { status: 2, text: "已评价", type: "warning" }, // 1
        { status: 3, text: "已转出", type: "warning" }, // 1
        { status: 4, text: "完成", type: "warning" }, // 1
        { status: 5, text: "待完成", type: "info" }, // 1
      ],
      Station: [
        { status: 0, text: "客户", type: "success" }, // 1
        { status: 1, text: "客服", type: "success" }, // 1
        { status: 2, text: "FAE", type: "success" }, // 1
        { status: 3, text: "研发", type: "success" }, // 1
      ],
      urgentStatus: [
        { status: 0, text: "特殊加急", type: "danger" }, // 1
        { status: 1, text: "较为紧急", type: "warning" }, // 1
        { status: 2, text: "一般紧急", type: "primary" }, // 1
        { status: 3, text: "普通客诉", type: "info" }, // 1
      ],

      totalCount: 0, // 总数据条数
      status: null, // 查询文章的状态，不传就是全部
      channels: [], // 文章频道列表
      channelId: null, // 查询文章的频道
      rangeDate: null, // 筛选的范围日期
      loading: true, // 表单数据加载中 loading
      showchangepsw: false,
      form: {
        creatTimeBefore: null,
        createTimeAfter: null,
        customerName: null,
        phoneNumber: null,
        processes: null,
        type: null,

        ownerType: 2,
        size: 20, // 每页大小
        page: 1, // 当前页码
        from: null,
        selfFlag: null,
      },
      solvform: {
        mode: 0,
        reason: null,
        remark: null,
        solution: null,
        workOrderId: "",
        attachment: null,
        faeOperatorName: null,
      },

      steptype:0,
      customerWorkOrderVo:{},
      technicalSupportWorkOrderVo:{}
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getFaeOperatorList();
    window.addEventListener("resize", this.getTableHeight);
    let token = JSON.parse(window.localStorage.getItem("usertoken"));
    this.multfilehead = { Authorization: token };
  },
  destroyed() {
    window.removeEventListener("resize", this.getTableHeight);
  },
  mounted() {
    this.form.selfFlag = null;
    this.restaurants = this.loadAll();
    this.loadArticles(1);
  },
  methods: {
    async getFaeOperatorList() {
      try {
        const res = await getFaeOperatorListUrl();
        if (res.data.code === 200) {
          this.FAElist = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },
    async reopen(row, column, event) {
      try {
        const res = await reopenFaeUrl({ workOrderId: row.workOrderId });
        if (res.data.code === 200) {
          this.$message({
            message: "已重新打开FAE工单",
            type: "success",
          });
          this.loadArticles(1);
          this.planVisable = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    async finish(row, column, event) {
      try {
        const res = await completeOrderUrl({ workOrderId: row.workOrderId });
        if (res.data.code === 200) {
          this.$message({
            message: "此工单已完成",
            type: "success",
          });
          this.loadArticles(1);
          this.planVisable = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    cleardata() {
      (this.form = {
        creatTimeBefore: null,
        createTimeAfter: null,
        customerName: null,
        phoneNumber: null,
        processes: null,
        type: null,

        ownerType: 2,
        size: 20, // 每页大小
        page: 1, // 当前页码
        from: null,
        selfFlag: null,
      }),
        this.loadArticles();
    },
    getTableHeight() {
      this.tableHeight = window.innerHeight - 320;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.process == 1) {
        return "complate-row";
      }

      return "white-row";
    },
    handleSizeChange(val) {
      this.form.size = val;
      this.loadArticles();
    },
    async onChangePSWD() {
      try {
        const res = await changePassWord(this.changepsdform);
        if (res.data.code === 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showchangepsw = false;
      }
    },
    openChange() {
      this.showchangepsw = true;
    },
    reflesh() {
      this.loadArticles();
    },

    uploadsuccess(response, file, fileList) {
      console.log("successs");
      if (this.solvform.attachment === null) {
        this.solvform.attachment = response.data.path;
      } else {
        this.solvform.attachment =
          this.solvform.attachment + "," + response.data.path;
      }
    },
    async solveOrder() {
      try {
        const res = await solveOrderUrl(this.solvform);
        if (res.data.code === 200) {
          this.$message({
            message: "信息填写成功",
            type: "success",
          });
          this.planVisable = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.planVisable = false;
        this.loadArticles();
      }
    },
    makeAddfale(data) {
      this.AddVisible = data;
    },
    outlogin() {
      this.$confirm(this.$t("sftc"), this.$t("tuichu"), {
        confirmButtonText: this.$t("shi"),
        cancelButtonText: this.$t("fou"),
        type: "warning",
      })
        .then(() => {
          localStorage.clear();
          this.$router.push({
            name: "Login",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },

    handleUpload(file) {
      let formData = new FormData();
      formData.append("file", file.file);
      formData.append("token", this.token);
      formData.append("zzh", this.fileQuery.zzh);

      uploadFile(formData).then((res) => {
        if (res.data.code == 200 && res.data.success) {
          this.$message.success("上传成功");
        } else {
          this.$message(res.data.message);
        }
      });
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return [
        { value: "卡停机" },
        { value: "卡充值问题" },
        { value: "平台到期 " },
        { value: "设备离线" },
        { value: "换卡" },
        { value: "返修咨询" },
        { value: "设备解绑问题" },
        { value: "声控失灵" },
        { value: "指令问题" },
        { value: "到期延长" },
        { value: "到期延长" },
        { value: "api接口问题" },
        { value: "APP无法登陆" },
        { value: "APP如何下载" },
        { value: "AMS创建客户" },
      ];
    },
    handleSelect(item) {},
    addorder() {
      this.AddVisible = true;
    },
    cansel() {
      this.delectview = false;
    },
    addplan(row, column, event) {
      this.multfiledata.workOrderId = row.workOrderId;
      this.fileList = [];
      this.solvform.attachment = null;
      this.planVisable = true;
      this.solvform = { ...row };
      this.getworkerorderDetaile(row.workOrderId);
    },
    async getworkerorderDetaile(id) {
      try {
        const res = await OneOrderUrl({ workOrderId: id });
        if (res.data.code === 200) {
          console.log(res);
          if(res.data.data.customerWorkOrderVo)
          this.customerWorkOrderVo = res.data.data.customerWorkOrderVo
          if(res.data.data.technicalSupportWorkOrderVo)
          this.technicalSupportWorkOrderVo = res.data.data.technicalSupportWorkOrderVo
          if (res.data.data.customerWorkOrderVo.process == 0) {
            this.steptype = 1;
          } else if (res.data.data.customerWorkOrderVo.process == 3) {
            this.steptype = 3;
          } else if (res.data.data.customerWorkOrderVo.process == 5) {
            this.steptype = 5;
          } else if (res.data.data.customerWorkOrderVo.process == 1) {
            this.steptype = 7;
          } else {
            this.steptype = 5;
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    delect(row, column, event) {
      this.deletedvis = true;
      this.delectform.workOrderId = row.workOrderId;
      this.delectform.orderNo = row.orderNo;
    },

    async sendFAE() {
      try {
        const res = await OrderTransform(this.solvform);
        if (res.data.code === 200) {
          this.$message({
            message: "转至FAE成功",
            type: "success",
          });

          this.loadArticles(1);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.planVisable = false;
        this.opensetfaename = false;
      }
    },
    async deletedOrder() {
      try {
        const res = await OrderDelect(this.delectform);
        if (res.data.code === 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });

          this.loadArticles(1);
          this.deletedvis = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    complateorder(row, column, event) {
      this.ComplectVisible = true;
      this.complaterform.workOrderId = row.workOrderId;
    },

    exportexcel() {},
    changedata(row, column, event) {},

    async loadArticles(page = 1) {
      if (this.form.selfFlag == 1) {
        this.showremake = true;
      } else {
        this.showremake = false;
      }
      // 展示加载中 loading
      this.loading = true;
      try {
        const res = await SearchOrderUrl(this.form);
        if (res.data.code === 200) {
          this.articles = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },

    onCurrentChange(page) {
      this.loadArticles(page);
    },

    onDeleteArticle(articleId) {
      this.$confirm("确认删除吗？", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 确认执行这里
          deleteArticle(articleId.toString()).then((res) => {
            // 删除成功，更新当前页的文章数据列表
            this.loadArticles(this.form.page);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // 找到数据接口
      // 封装请求方法
      // 删除请求调用
    },
  },
};
</script>

<style scoped lang="less">
.header {
  float: right;
}

.article-container {
  height: 100%;
}

.list-table {
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 200px;
  margin-right: 10px;
}
.filter-card {
  margin-bottom: 5px;
}
.flexparents {
  margin-bottom: 5px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}

.zdhh {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.floter {
  display: flex;
  justify-content: space-between;
}
/deep/.el-table .warning-row {
  background: oldlace;
  font-weight: bold;
  font-size: 12px;
}
/deep/.el-table .complate-row {
  background: #ffffff;
}
/deep/.el-table .white-row {
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
}
</style>
